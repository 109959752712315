/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import LegalBanner from '../../components/legal-banner';
import Content from '../../components/content';
import ContentTOC from '../../components/content-toc';
import Link from './../../components/link';
import SEO from "../../components/seo";
import { getOgImage } from "./../../utils/functions";

/**
 * Legal and Privacy Child Page.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const LegalPrivacyChild = ( { pageContext } ) => {

	const { seo, uri } = pageContext;
	const bannerData   = {
		bannerOption: isEmpty( pageContext.privacyPolicyMeta.bannerOption ) ? null : pageContext.privacyPolicyMeta.bannerOption,
		featuredImage: isEmpty( pageContext.featuredImage.node ) ? null : pageContext.featuredImage.node
	};

	const tocContent = pageContext.privacyPolicyMeta.contentList;
	const cta = pageContext.privacyPolicyMeta.ctaOption;
	const openGraphImage = getOgImage( seo );

	return (
		<Layout title={ pageContext.privacyPolicyMeta.bannerOption.headline } uri={ uri } className="page legal-and-privacy-child">
			<SEO
				title={ pageContext.privacyPolicyMeta.bannerOption.headline }
				seoData={ seo }
				uri={ uri }
				header={ { siteTitle: 'OpenWeb' } }
				openGraphImage={ openGraphImage }
			/>
			<LegalBanner page={ bannerData } />

			{ ! isEmpty( pageContext.content ) ? (
				<Content page={ pageContext } />
			) : null }

			{ ! isEmpty( tocContent.list ) ? (
				<ContentTOC meta={ tocContent } />
			) : null }

			{ ! isEmpty( cta.link ) ? (
				<section className="legal-read-more">
					<div className="row">
						<div className="small-12 columns">
							<Link { ...cta.link } withArrow={ true } />
						</div>
					</div>
				</section>
			) : null }

		</Layout>
	);
};

LegalPrivacyChild.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default LegalPrivacyChild;
