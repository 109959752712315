/**
 * External dependencies.
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

/**
 * Mobile header component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const Header = ( { list, scrollToElement, currentIndex, inView, isExpanded, setExpanded } ) => {
	const containerClass = classnames( 'content-toc-header', {
		'is-expanded': isExpanded,
	} );

	const collapse = () => {
		setExpanded( false );
	};

	const onLinkClick = ( event, index ) => {
		scrollToElement( event, index );
		collapse();
	};

	useEffect( () => {
		window.addEventListener( 'scroll', collapse );

		return window.removeEventListener( 'scroll', collapse );
	}, [] ); // eslint-disable-line

	if ( ! inView || isEmpty( list ) ) {
		return null;
	}

	return (
		<>
			{/*<a className="content-toc-overlay" onClick={ () => setExpanded( false ) } /> { /* eslint-disable-line */ }*/}
			<div className={ containerClass } >
				<div className="content-toc-header__container">
					<ul className="content-toc-header__list">
						{ list.map( ( data, index ) => {
							const liClassNames = classnames( 'content-toc-header__list-item', {
								'is-active': currentIndex === index,
							} );

							return (
								<li key={ `toc-title-${ index }` } className={ liClassNames }>
									<a onClick={ ( event ) => onLinkClick( event, index ) } href={ `#toc-${ index }` } className="content-toc-header__item-link">{ data.title }</a>
								</li>
							);
						} ) }
					</ul>
					<button onClick={ () => setExpanded( ! isExpanded ) } className="content-toc-header__toggle-button">Toggle</button>
				</div>
			</div>
		</>
	);
};

Header.propTypes = {
	list: PropTypes.array,
	scrollToElement: PropTypes.func,
	currentIndex: PropTypes.number,
};

Header.defaultProps = {
	list: [],
};

export default Header;
