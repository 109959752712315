/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { sanitize } from '../../utils/functions';

/**
 * Content Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const Content = ( { data, contentRender, index } ) => {

	return (
		<div className="content-toc__content">
			{ contentRender ? contentRender( data, index ) : (
				<>
					<p className="content-toc__count">{ index + 1 }. { data.title }</p>
					<div className="content-toc__text" dangerouslySetInnerHTML={ { __html: sanitize( data.content ) } } />
				</>
			) }
		</div>
	)
};

Content.propTypes = {
	data: PropTypes.object,
	contentRender: PropTypes.func,
	index: PropTypes.number,
};

Content.default = {
	data: {},
}

export default Content;
