/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { sanitize } from '../../utils/functions';

/**
 * Internal dependencies.
 */
import Image from './../image/index';

import './style.scss';

/**
 * Legal Child Page Banner Component.
 *
 * @param {Object} page Page props.
 *
 * @return {jsx}
 */
const LegalBanner = ( { page } ) => {

	const { bannerOption: { headline, subHeadline }, featuredImage } = page;

	return (
		<section className="legal-banner">
			<div className="row">
				<div className="small-12 columns">
					<figure className="legal-banner__figure">
						<Image { ...featuredImage } className="legal-banner__icon" />
					</figure>
					<h1 className="legal-banner__title" dangerouslySetInnerHTML={ { __html: sanitize( headline ) } } />
					<div className="legal-banner__sub-heading" dangerouslySetInnerHTML={ { __html: sanitize( subHeadline ) } } />
				</div>
			</div>
		</section>
	);

};

LegalBanner.propTypes = {
	page: PropTypes.object,
};

LegalBanner.defaultProps = {
	page: {
		bannerOption: {
			headline: '',
			subHeadline: '',
		},
		featuredImage: {
			altText: '',
			mediaDetails: {
				width: null,
				height: null,
			},
			srcSet: null,
			sourceUrl: '',
			sizes: null
		},
	}
};

export default LegalBanner;
