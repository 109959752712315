/**
 * External dependencies.
 */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { animateScroll } from 'react-scroll';
import { isEmpty, debounce, memoize } from 'lodash';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import Content from './content';
import Header from './header';
import { useDevice } from '../../hooks';

import './style.scss';

/**
 * Content with TOC Component.
 *
 * @param {Object} props Meta props.
 *
 * @return {jsx}
 */
const ContentTOC = ({ meta, contentRender, className, title }) => {
	const { list } = meta;
	const contentRefs = [];
	const [pageYOffset, setPageYOffset] = useState(0);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [inView, setInView] = useState(false);
	const [isHeaderExpanded, setHeaderExpanded] = useState(false);
	const device = useDevice();
	const topMargin = 'mobile' === device ? 180 : 120;
	const containerRef = useRef();

	const onScroll = debounce(() => {
		setPageYOffset(window.pageYOffset);
	}, 10);

	const containerTopBottom = memoize(() => {
		const top = containerRef.current.offsetTop - topMargin;
		const bottom = containerRef.current.offsetHeight + top;

		return { top, bottom };
	});

	useEffect(() => {
		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	}, []); // eslint-disable-line

	useEffect(() => {
		const headerMargin = 160;
		const offsetTop = window.pageYOffset;
		const containerInView = (offsetTop > containerTopBottom().top && offsetTop < containerTopBottom().bottom);

		setInView(containerInView);

		if (!containerInView) {
			setHeaderExpanded(false);
		}

		contentRefs.forEach((ref) => {
			if (ref) {
				const currentTopPosition = ref.offsetTop - offsetTop - headerMargin;

				if (currentTopPosition < 0 && currentTopPosition > -50) {
					setCurrentIndex(parseInt(ref.dataset.index));
				}
			}
		});
	}, [pageYOffset]); // eslint-disable-line

	if (isEmpty(list)) {
		return null;
	}

	const scrollToElement = (event, index) => {
		event.preventDefault();

		if (contentRefs[index]) {
			animateScroll.scrollTo(contentRefs[index].offsetTop - topMargin, {
				duration: 500,
			});
			setCurrentIndex(index);
		}
	}

	const containerClassNames = classnames('content-toc', className);

	return (
		<>
			{'mobile' === device ? (
				<Header
					list={list}
					scrollToElement={scrollToElement}
					currentIndex={currentIndex}
					inView={inView}
					isExpanded={isHeaderExpanded}
					setExpanded={setHeaderExpanded}
				/>
			) : null}
			<section ref={containerRef} className={containerClassNames} >
				{title ? (
					<div className="row align-center">
						<div className="medium-10 small-12 column">
							<h2 className="content-toc__section-title">{title}</h2>
						</div>
					</div>
				) : null}
				<div className={`row ${className}__content`}>
					<div className="large-2 medium-3 small-12 columns hide-for-small-only">
						<ul className="content-toc__lists">
							{list.map((data, index) => (
								!isEmpty(data.jobs) ? (
									<li key={`toc-title-${index}`} className="content-toc__list">
										<a onClick={(event) => scrollToElement(event, index)} href={`#toc-${index}`} className={currentIndex === index ? 'content-toc__item is-active' : 'content-toc__item'}>{data.title}</a>
									</li>
								) : null
							))}
						</ul>
					</div>

					<div className="large-8 medium-9 small-12 columns large-offset-1">
						{list.map((data, index) => (
							<div ref={el => contentRefs[index] = el} key={`toc-content-${index}`} data-index={index} className="content-toc__contents" id={`toc-${index}`}>
								<Content
									data={data}
									index={index}
									contentRender={contentRender}
								/>
							</div>
						))}
					</div>
				</div>
			</section>
		</>
	);
};

ContentTOC.propTypes = {
	meta: PropTypes.object,
	contentRender: PropTypes.func,
	className: PropTypes.string,
	title: PropTypes.string,
};

ContentTOC.defaultProps = {
	meta: {
		list: [
			{
				title: '',
				content: '',
			}
		]
	}
};

export default ContentTOC;
